import React from 'react';
import LaunchingSoonPage from './components/LaunchingSoonPage';

function App() {
  return (
    <div className="App">
      <LaunchingSoonPage />
    </div>
  );
}

export default App;
